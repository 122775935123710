import * as THREE from 'three';
import { OrbitControls, TessellateModifier, ThreeMFLoader } from 'three/examples/jsm/Addons.js';
import * as dat from 'dat.gui';
import brickTexture from '../Texture/brick.jpg';
import woodTexture from '../Texture/wood.jpg';
import floorTexture from '../Texture/floor.jpg';
import concreteTexture from '../Texture/concrete.jpg'
//Texture
const textureLoader = new THREE.TextureLoader();
// Renderer
const renderer = new THREE.WebGLRenderer( );
//Activate shadows or enable renderer to show shadows
renderer.shadowMap.enabled = true;
renderer.setClearColor(0x000000);
renderer.setSize(window.innerWidth, window.innerHeight);
renderer.setPixelRatio(window.setPixelRatio);
document.body.appendChild(renderer.domElement);

//Scene
const scene = new THREE.Scene();

//Camera
const camera = new THREE.PerspectiveCamera(
    45,//FOV
    window.innerWidth/window.innerHeight,//Aspect ratio
    0.1,//Near plane
    1000//Far plane
);
camera.position.set(0,18,45);
camera.lookAt(0,0,0);
// Adding orbit controls 
const orbit = new OrbitControls(camera, renderer.domElement);
orbit.update();
orbit.enableRotate=true;
orbit.enablePan=false;
orbit.enableZoom=true;
orbit.zoomSpeed=3;
// wood material
const woodMaterial = new THREE.MeshStandardMaterial(
    {   color:0x666666,
        map: textureLoader.load(woodTexture)
    }
);
//Brick material
const brickMaterial = new THREE.MeshStandardMaterial(
    {  color:0x777777,
        map: textureLoader.load(brickTexture)
    }
);

//Concrete material
const concreteMaterial = new THREE.MeshStandardMaterial(
    {  color:0x777777,
        map: textureLoader.load(concreteTexture)
    }
);

// Plane
const planeGeometry = new THREE.PlaneGeometry(100,100);
const planeMaterial = new THREE.MeshStandardMaterial(
                                {color:0x555555,
                                 side:THREE.DoubleSide,
                                 map: textureLoader.load(floorTexture)
                                });
const plane = new THREE.Mesh(planeGeometry, planeMaterial);
plane.rotation.x = -0.5 *Math.PI;
plane.receiveShadow = true;
scene.add(plane);

// Cube
const cubeGeometry = new THREE.BoxGeometry(8,8,8);
const cubeMaterial = new THREE.MeshStandardMaterial({
    color:0x220000
});
const cube = new THREE.Mesh(cubeGeometry,cubeMaterial);
cube.position.set(0,4,0);
cube.castShadow=true;
scene.add(cube);

//Cube 2
const cube2Geometry = new THREE.SphereGeometry(2);
const cube2Material = new THREE.MeshStandardMaterial({
    color:0x000000
});
const cube2 = new THREE.Mesh(cube2Geometry,cube2Material);
cube2.position.set(0,2,25);
cube2.castShadow=true;
scene.add(cube2);
cube2.material = concreteMaterial;

//Cube3
const cube3Geometry = new THREE.SphereGeometry(1.8);
const cube3Material = new THREE.MeshStandardMaterial({
    color:0x000000
});
const cube3 = new THREE.Mesh(cube3Geometry,cube3Material);
cube3.position.set(5,2,25);
cube3.castShadow=true;
scene.add(cube3);
cube3.material=woodMaterial;
//Cube4
const cube4Geometry = new THREE.SphereGeometry(1.8);
const cube4Material = new THREE.MeshStandardMaterial({
    color:0x000000
});
const cube4 = new THREE.Mesh(cube4Geometry,cube4Material);
cube4.position.set(-5,2,25);
cube4.castShadow=true;
scene.add(cube4);
cube4.material=brickMaterial;

//Donut
const donutGeometry = new THREE.TorusGeometry(2,1);
const donutMaterial = new THREE.MeshStandardMaterial({
    color:0x331100
});
const donut = new THREE.Mesh(donutGeometry,donutMaterial);
donut.position.set(12,3,15);
donut.castShadow=true;
scene.add(donut);

//Donut 2
const donut2Geometry = new THREE.TorusGeometry(2,1);
const donut2Material = new THREE.MeshStandardMaterial({
    color:0x332200
});
const donut2 = new THREE.Mesh(donut2Geometry,donut2Material);
donut2.position.set(-12,3,15);
donut2.castShadow=true;
scene.add(donut2);

//Ambient light
const ambientLight = new THREE.AmbientLight(0xaaaaaa,3);
scene.add(ambientLight);

//Spotlight
const spotlight = new THREE.SpotLight(0xffffff,9999);
scene.add(spotlight);
spotlight.position.set(0,25,0);
spotlight.castShadow = true;
spotlight.angle=5;
spotlight.penumbra=1;
//Spotlight 2
const spotlight2 = new THREE.SpotLight(0xffffff,10000);
scene.add(spotlight2);
spotlight2.position.set(30,30,50);
spotlight2.castShadow = true;
spotlight2.angle=0.5;
spotlight2.penumbra=1;






//GUI
/*
const gui = new dat.GUI();
const options={
    visible:true,
    rotate:false,
    height:1,
    width:1,
    wood:false,
    brick:false,
   
}
gui.add(options,'visible').onChange(function(e){
    cube.material.visible=e;
});


gui.add(options,'rotate').onChange(function(e){
   e?rotation=true:rotation=false;
});
gui.add(options,'height',1,4).onChange(function(e){
    cube.scale.y=e;
    cube.position.y=e*4;
});
gui.add(options,'width',1,4).onChange(function(e){
    cube.scale.x=e;
});
gui.add(options,'wood').onChange(function(e){
    e?cube.material= woodMaterial:cube.material=cubeMaterial;
});
gui.add(options,'brick').onChange(function(e){
    e?cube.material= brickMaterial:cube.material=cubeMaterial;
});
*/

//Rotate cube
/*
let rotation = true;
function rotateCube(){
    if(rotation){
        cube.rotation.y += 0.01;
    }
}
*/
//Ray caster

const mousePosition = new THREE.Vector2();

window.addEventListener('mousemove', function(e){
    mousePosition.x = (e.clientX / window.innerWidth)*2 - 1;
    mousePosition.y = -(e.clientY / window.innerHeight)*2 +1;
});

const rayCaster = new THREE.Raycaster();

cube2.name = 'concrete';
cube3.name = 'wood';
cube4.name = 'brick';
donut.name = 'donut';
donut2.name = 'donut2';
function animate(){
    
    donut.rotation.y +=0.02;
    donut2.rotation.y -=0.02;
   rayCaster.setFromCamera(mousePosition,camera);
   const intersects = rayCaster.intersectObjects(scene.children);

    for(let i =0;i<intersects.length;i++){
        if(intersects[i].object.name === 'concrete')
           cube.material=concreteMaterial;
        if(intersects[i].object.name === 'wood')
            cube.material=woodMaterial;
        if(intersects[i].object.name === 'brick')
            cube.material=brickMaterial;
        if(intersects[i].object.name === 'donut')
            cube.rotation.y += 0.02;
        if(intersects[i].object.name === 'donut2')
            cube.rotation.y -= 0.02;
    }
   renderer.render(scene, camera);
}

renderer.setAnimationLoop(animate);


